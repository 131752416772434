import React, { useState } from 'react';
import Latex from 'react-latex-next';
import quizData from './quizData.json';

const QuizComponent = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showDescription, setShowDescription] = useState(false);

  const handleNextQuestion = () => {
    if (selectedAnswer === quizData.questions[currentQuestion].correctAnswers) {
      setScore(score + 1);
    }
    setSelectedAnswer(null);
    setShowDescription(false);
    if (currentQuestion < quizData.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      alert(`Quiz finished! Your score is ${score + 1} out of ${quizData.questions.length}`);
    }
  };

  const handleChoiceChange = (index) => {
    setSelectedAnswer(index);
    setShowDescription(true);
  };

  const renderChoices = (choices) => {
    return choices.map((choice, index) => (
      <div key={index}>
        <input
          type="radio"
          name="choice"
          value={index}
          checked={selectedAnswer === index}
          onChange={() => handleChoiceChange(index)}
        />
        <label>
          {quizData.questions[currentQuestion].latexStatus ? (
            <Latex>{choice}</Latex>
          ) : (
            choice
          )}
        </label>
      </div>
    ));
  };

  const renderQuestion = (question, latexStatus) => {
    return latexStatus ? (
      <Latex>{question}</Latex>
    ) : (
      <p>{question}</p>
    );
  };

  const renderDescription = (description, latexStatus) => {
    return description.map((desc, index) => (
      <p key={index}>
        {latexStatus ? <Latex>{desc}</Latex> : desc}
      </p>
    ));
  };

  return (
    <div>
      {renderQuestion(
        quizData.questions[currentQuestion].question,
        quizData.questions[currentQuestion].latexStatus
      )}
      {renderChoices(quizData.questions[currentQuestion].choices)}
      {showDescription && (
        <div>
          {renderDescription(
            quizData.questions[currentQuestion].description,
            quizData.questions[currentQuestion].latexStatus
          )}
        </div>
      )}
      <button onClick={handleNextQuestion} disabled={selectedAnswer === null}>
        Next
      </button>
    </div>
  );
};

export default QuizComponent;
